
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { approvalService } from "@/service";
export type ApprovalLists = {
  approval_log: string;
  approval_reason: string;
  duty_name: string;
  from_name: string;
  head_pic: string;
  id: string;
  name: string;
};
export interface Process {
  approval_lists: ApprovalLists[];
  approval_state_name: string;
  node_date: string;
  node_id: string;
  node_name: string;
  reason: string;
  state: string;
  create_time: string;
  user_info: {
    duty_name: string;
    from_name: string;
    head_pic: string;
    id: string;
    name: string;
  };
  way_msg: string;
}

export default defineComponent({
  name: "Process",
  setup() {
    const route = useRoute();
    const { id } = route.params;
    const processList = ref<Process[]>([]);
    const getProcessLists = async () => {
      const { data } = await approvalService.getProcessLists(id as string);
      processList.value = data.data;
    };
    getProcessLists();

    const currentApprovalList = ref<Process["approval_lists"]>([]);
    const dialogShow = ref(false);
    const onClick = (process: Process) => {
      if (process.approval_lists.length === 0) return;
      currentApprovalList.value = process.approval_lists;
      dialogShow.value = true;
    };

    return { currentApprovalList, dialogShow, onClick, processList };
  }
});
