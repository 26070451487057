import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "uf-process" }
const _hoisted_2 = { class: "uf-process__top" }
const _hoisted_3 = { class: "uf-process__title" }
const _hoisted_4 = { class: "uf-process__date" }
const _hoisted_5 = { class: "uf-process__content-wrap" }
const _hoisted_6 = { class: "uf-process__content-name" }
const _hoisted_7 = { class: "uf-process__content-msg" }
const _hoisted_8 = { class: "uf-process-approval__list" }
const _hoisted_9 = { class: "uf-process-approval__item-avatar" }
const _hoisted_10 = { class: "uf-process-approval__item-info" }
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { class: "duty_name" }
const _hoisted_13 = { class: "reason" }
const _hoisted_14 = { class: "uf-process-approval__item-log" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_dialog = _resolveComponent("van-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.processList, (process, index) => {
        return (_openBlock(), _createBlock("div", {
          key: process.node_id,
          class: ["uf-process__item", [
        { 'uf-process__item--last': index === _ctx.processList.length - 1 },
        ['uf-process__item--state1', 'uf-process__item--state2', 'uf-process__item--state3'][Number(process.state) + 1]
      ]]
        }, [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(process.node_name), 1),
            _createVNode("div", _hoisted_4, _toDisplayString(process.node_date), 1)
          ]),
          (index != _ctx.processList.length - 1)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: "uf-process__content",
                onClick: ($event: any) => (_ctx.onClick(process))
              }, [
                _createVNode("div", _hoisted_5, [
                  _createVNode("div", _hoisted_6, _toDisplayString(process.user_info.from_name) + "-" + _toDisplayString(process.user_info.name), 1),
                  _createVNode("div", _hoisted_7, _toDisplayString(process.way_msg) + " " + _toDisplayString(process.way_msg && process.approval_state_name ? "," : "") + " " + _toDisplayString(process.approval_state_name), 1)
                ]),
                (process.approval_lists.length > 0)
                  ? (_openBlock(), _createBlock(_component_van_icon, {
                      key: 0,
                      name: "arrow"
                    }))
                  : _createCommentVNode("", true)
              ], 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ]),
    _createVNode(_component_van_dialog, {
      teleport: ".uf-process",
      show: _ctx.dialogShow,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogShow = $event)),
      title: "审批人",
      "close-on-click-overlay": "",
      "show-confirm-button": false
    }, {
      default: _withCtx(() => [
        _createVNode("ul", _hoisted_8, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.currentApprovalList, (item, index) => {
            return (_openBlock(), _createBlock("li", {
              class: "uf-process-approval__item",
              key: index
            }, [
              _createVNode("div", _hoisted_9, [
                _createVNode("img", {
                  src: item.head_pic
                }, null, 8, ["src"])
              ]),
              _createVNode("div", _hoisted_10, [
                _createVNode("div", _hoisted_11, _toDisplayString(item.name), 1),
                _createVNode("div", _hoisted_12, _toDisplayString(item.duty_name), 1),
                _createVNode("div", _hoisted_13, _toDisplayString(item.approval_reason), 1)
              ]),
              _createVNode("div", _hoisted_14, _toDisplayString(item.approval_log), 1)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}